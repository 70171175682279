/* BEGIN Thin */
@font-face {
  font-family: Roboto Thin;
  src: url("../fonts/Thin/Roboto-Thin.woff2?v=2.137") format("woff2"), url("../fonts/Thin/Roboto-Thin.woff?v=2.137") format("woff");
  font-weight: 100;
  font-style: normal;
}
/* END Thin */
/* BEGIN Thin Italic */
@font-face {
  font-family: Roboto Thin;
  src: url("../fonts/ThinItalic/Roboto-ThinItalic.woff2?v=2.137") format("woff2"), url("../fonts/ThinItalic/Roboto-ThinItalic.woff?v=2.137") format("woff");
  font-weight: 100;
  font-style: italic;
}
/* END Thin Italic */
/* BEGIN Light */
@font-face {
  font-family: Roboto Light;
  src: url("../fonts/Light/Roboto-Light.woff2?v=2.137") format("woff2"), url("../fonts/Light/Roboto-Light.woff?v=2.137") format("woff");
  font-weight: 300;
  font-style: normal;
}
/* END Light */
/* BEGIN Light Italic */
@font-face {
  font-family: Roboto Light;
  src: url("../fonts/LightItalic/Roboto-LightItalic.woff2?v=2.137") format("woff2"), url("../fonts/LightItalic/Roboto-LightItalic.woff?v=2.137") format("woff");
  font-weight: 300;
  font-style: italic;
}
/* END Light Italic */
/* BEGIN Regular */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("../fonts/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url("../fonts/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("../fonts/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* END Regular */
/* BEGIN Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Italic/Roboto-Italic.woff2?v=2.137") format("woff2"), url("../fonts/Italic/Roboto-Italic.woff?v=2.137") format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: Roboto;
  src: url("../fonts/Italic/Roboto-Italic.woff2?v=2.137") format("woff2"), url("../fonts/Italic/Roboto-Italic.woff?v=2.137") format("woff");
  font-weight: normal;
  font-style: italic;
}
/* END Italic */
/* BEGIN Medium */
@font-face {
  font-family: Roboto Medium;
  src: url("../fonts/Medium/Roboto-Medium.woff2?v=2.137") format("woff2"), url("../fonts/Medium/Roboto-Medium.woff?v=2.137") format("woff");
  font-weight: 500;
  font-style: normal;
}
/* END Medium */
/* BEGIN Medium Italic */
@font-face {
  font-family: Roboto Medium;
  src: url("../fonts/MediumItalic/Roboto-MediumItalic.woff2?v=2.137") format("woff2"), url("../fonts/MediumItalic/Roboto-MediumItalic.woff?v=2.137") format("woff");
  font-weight: 500;
  font-style: italic;
}
/* END Medium Italic */
/* BEGIN Bold */
@font-face {
  font-family: Roboto Bold;
  src: url("../fonts/Bold/Roboto-Bold.woff2?v=2.137") format("woff2"), url("../fonts/Bold/Roboto-Bold.woff?v=2.137") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url("../fonts/Bold/Roboto-Bold.woff2?v=2.137") format("woff2"), url("../fonts/Bold/Roboto-Bold.woff?v=2.137") format("woff");
  font-weight: bold;
  font-style: normal;
}
/* END Bold */
/* BEGIN Bold Italic */
@font-face {
  font-family: Roboto Bold;
  src: url("../fonts/BoldItalic/Roboto-BoldItalic.woff2?v=2.137") format("woff2"), url("../fonts/BoldItalic/Roboto-BoldItalic.woff?v=2.137") format("woff");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Roboto;
  src: url("../fonts/BoldItalic/Roboto-BoldItalic.woff2?v=2.137") format("woff2"), url("../fonts/BoldItalic/Roboto-BoldItalic.woff?v=2.137") format("woff");
  font-weight: bold;
  font-style: italic;
}
/* END Bold Italic */
/* BEGIN Black */
@font-face {
  font-family: Roboto Black;
  src: url("../fonts/Black/Roboto-Black.woff2?v=2.137") format("woff2"), url("../fonts/Black/Roboto-Black.woff?v=2.137") format("woff");
  font-weight: 900;
  font-style: normal;
}
/* END Black */
/* BEGIN Black Italic */
@font-face {
  font-family: Roboto Black;
  src: url("../fonts/BlackItalic/Roboto-BlackItalic.woff2?v=2.137") format("woff2"), url("../fonts/BlackItalic/Roboto-BlackItalic.woff?v=2.137") format("woff");
  font-weight: 900;
  font-style: italic;
}
/* END Black Italic */